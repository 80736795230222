import { CookieKeys, sha256 } from '@finn/ui-utils';
import cookies from 'js-cookie';
import { SetStateAction, useEffect, useState } from 'react';

const SavedPassHash =
  '8075cc17eaf2b1fd73e527dcdb3e914ac10f81f30e48a1dc32c7dfa87b6c3a1d';

export const useIsCosmicHelperEnabled = () => {
  const [passHash, setPassHash] = useState('');

  useEffect(() => {
    const generateHash = async (val: string) => {
      const hash = await sha256(val);
      setPassHash(hash as SetStateAction<string>);
    };
    const query = new URLSearchParams(window.location.search);

    const queryValue = query.get(CookieKeys.COSMIC_HELPER);
    if (queryValue) {
      cookies.set(CookieKeys.COSMIC_HELPER, queryValue);
      generateHash(queryValue as string);
    }

    const featureValue = cookies.get(CookieKeys.COSMIC_HELPER);
    if (featureValue) {
      generateHash(featureValue);
    }
  }, []);

  return passHash === SavedPassHash;
};
